import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "productContainer" }
const _hoisted_2 = { class: "productLogoC" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "productTitle" }
const _hoisted_5 = { class: "productInfoC" }
const _hoisted_6 = { class: "b1" }
const _hoisted_7 = { class: "infoValue" }
const _hoisted_8 = { class: "b2" }
const _hoisted_9 = { class: "infoValue" }
const _hoisted_10 = { class: "b3" }
const _hoisted_11 = { class: "infoValue" }
const _hoisted_12 = { class: "b4" }
const _hoisted_13 = { class: "infoValue" }
const _hoisted_14 = { class: "b5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.item.bank.logo.small,
        class: "productLogo"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.item.bank.name), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "infoLabel" }, "ZDOLNOŚĆ", -1)),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.showAsCurrency(_ctx.item.ability.value, "PLN")), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "infoLabel" }, "OPROCENTOWANIE", -1)),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.item.interest.type === "fixed_interest_rate"
              ? _ctx.showAsPercent2(_ctx.item.interest.fixedInterestRate.value)
              : _ctx.showAsPercent2(_ctx.item.interest.value)), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "infoLabel" }, "MARŻA", -1)),
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.showAsPercent2(_ctx.item.margin.value)), 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "infoLabel" }, "RRSO", -1)),
        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.showAsPercent2(_ctx.item.rrso.value)), 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("button", {
          class: "productBtn",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showContact && _ctx.showContact(...args)))
        }, _toDisplayString(_ctx.resultButton), 1)
      ])
    ])
  ]))
}